.cart-heading {
  text-align: center;
  margin: 56px auto 32px;
  @include media-breakpoint-up(lg) {
    margin: 80px auto 48px;
    text-align: center;
  }
}

.woocommerce-page {
  .return-to-shop {
    margin: 0;
    display: flex;
    justify-content: center;

    a {
      //margin: 0 auto;
      //display: inline-block;
      @extend .btn-outline-primary;
      border-radius: 24px;
      border: 2px solid #005a8e;
      padding: 12px 32px 12px;
      font-size: 18px;
      font-weight: $font-weight-bolder;
      letter-spacing: .3px;
      margin: 0;
      position: relative;
      top: 0;
      left: 0;
      white-space: normal;
      text-align: left;
      background-color: transparent;
      height: 48px;
    }
  }

  .woocommerce {
    > .container {
      @include media-breakpoint-between(md, lg) {
        max-width: calc(100% - 24px);
        margin: 0 12px;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    @media (min-width: 768px) and (max-width: 768px) {
      .product-thumbnail {
        //display: none !important;
        display: table-cell !important;
      }
    }
  }

  .cart-form-wrapper {
    @include media-breakpoint-down(sm) {
      padding: 12px;
    }
  }

  form {
    //background-color: $white;
    //width: 100%;

    table {


      &.shop_table {
        border: none;

        thead {
          @include media-breakpoint-down(sm) {
            display: none;
          }
          @include media-breakpoint-only(md) {
            display: table-header-group;
            tr {
              display: table-row;
            }
          }

          th[class^='product-'] {
            background-color: $white;
            min-height: 48px;
            height: 48px;
          }

          @media (min-width: 768px) and (max-width: 768px) {
            .product-thumbnail {
              //display: none !important;
              display: table-cell !important;
            }
          }
        }
        tr {
          min-height: 138px;
          background-color: white;
          //margin-top: 4px;
          border-top: 4px solid $body-bg;
          display: table-row;

          td {
            //@include media-breakpoint-up(md) {
            //  display: table-cell;
            //  text-align: left;
            //}



            &.product-thumbnail {
              @include media-breakpoint-up(md) {
                width: 144px;
              }
              @include media-breakpoint-up(xl) {
                width: 192px;
              }
            }
          }
        }

        &.cart {
          tr td {
            @include media-breakpoint-up(md) {
              border-top: 4px solid $body-bg;
              //td {
              display: table-cell;
              text-align: left !important;
              background-color: transparent !important;
              //}
              &.product-thumbnail {
                border-top: 4px solid $body-bg !important;
              }
            }
          }
        }

        tbody .product-name {
          a {
            box-shadow: none;
          }
        }
      }

      .quantity {
        display: flex;
        width: 156px;
        .qty {
          background-color: transparent;
          border-radius: 24px;
          border: 2px solid $lightblue;
          height: 48px;
          width: 156px;
        }

        //.quantity-nav {
        //  height: 48px;
        //}
        //
        //.quantity-button {
        //  height: 24px;
        //}
      }

      .woocommerce-cart-form__cart-item.cart_item, &.shop_table .cart_item {
        td {
          &:before {
            content: none;
          }
        }


        @include media-breakpoint-down(sm) {
          padding-right: 24px;
          display: grid;
          grid-template-areas:
                  'a b b b b'
                  'a d d c c'
                  'e e g g f';
          //grid-template-areas:
          //        'a b b b'
          //        'a c c c'
          //        'd d f e';
          grid-template-columns: 96px 84px 48px auto;
          grid-column-gap: 0;
          &:nth-child(2n) td {
            background-color: white;
            border: 0;
          }
          + tr {
            border-top: 4px solid $body-bg;
          }
          td {
            border-top: none;
          }
          .product-thumbnail {
            grid-area: a;
            display: flex;
            align-items: center;
            align-content: center;

            a {
              margin: 20px auto 0;
              max-width: 56px;
            }
          }
          .product-name {
            grid-area: b;
            text-align: left !important;
            padding: 20px 0 12px;
            font-size: 20px;
            line-height: 24px;
          }
          .regular-product-price {
            grid-area: c;
            text-align: left !important;
            padding: 0;
            line-height: 24px;
            span {
              font-size: 14px;
              line-height: 24px;
              letter-spacing: .3px;
            }
            font-size: 14px;
          }
          .product-price {
            grid-area: d;
            text-align: left !important;
            padding: 0;
            line-height: 24px;
            span {
              font-size: 14px;
              font-weight: bold;
              line-height: 24px;
              letter-spacing: .3px;
            }
          }
          .product-quantity {
            grid-area: e;
            padding: 20px 0;
            margin-left: 24px;
            text-align: left !important;

            //qua {
            //  margin-left: 24px;
            //}

            .quantity-nav {
              display: none;
            }
          }
          .product-subtotal {
            grid-area: f;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 20px;
            line-height: 24px;
          }
          .product-remove {
            grid-area: g;
            padding: 20px 0;

            .remove {
              width: 48px;
              height: 48px;
              margin: 0;
            }
          }
        }
      }


      .product-thumbnail {
        padding: 0;
        @include media-breakpoint-down(sm) {
          display: block !important;
        }
        //@media (min-width: 768px) and (max-width: 768px) {
        @include media-breakpoint-only(md) {
          display: table-cell !important;
          //#{&}th {
          //    display: none !important;
          //  }
        }

        a {
          //max-width: 96px;
          //margin: 20px 24px 20px 32px;
          margin: 20px 24px;
          display: flex;
          box-shadow: none;
          justify-content: center;
          align-items: center;
          @include media-breakpoint-up(xl) {
            margin: 20px 40px 20px 56px;
          }
        }

        img {
          text-indent: -9999px;
          //width: 100%;
          max-height: 96px;
          width: auto;
        }
      }
      @include media-breakpoint-only(md) {
        th, td {
          padding-left: 0 !important;
        }
      }
      @include media-breakpoint-between(md, lg) {
        th.regular-product-price, td.regular-product-price {
          display: none !important;
        }

        .quantity, .quantity .qty {
          width: 132px;
        }
        td .product-thumbnail {
          width: 96px;
        }
      }
      .product-name {
        font-weight: $font-weight-bold;
        padding-left: 0;
        @include media-breakpoint-only(md) {
          text-align: left !important;
        }
      }
      .product-price {
        span {
          line-height: 24px;
        }
      }
      .regular-product-price {
        background-color: transparent;
        span, bdi {
          opacity: 1;
          color: $dark;
        }
      }
      .product-price {
        .price-wording {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
        .regular-product-price {
          display: none;
          opacity: .5;
          @include media-breakpoint-between(md, lg) {
            display: block;
          }
        }
      }

      .product-subtotal {
        font-weight: $font-weight-bolder;

      }
      .cart-discount th span {
        font-weight: $font-weight-base !important;
      }
    }

    .product-remove .remove {
      @extend .icon-delete;
      text-indent: -9999px;
      box-shadow: none;
      width: 56px;
      height: 56px;
      transition: $transition-base;

      &:hover {
        background: $body-bg icon(icon-delete, $primary) center no-repeat;
        background-size: 24px 24px;
        transition: $transition-base;
      }
    }

    button[name="update_cart"],
    input[name="update_cart"] {
      display: none;
    }

    .update-cart {
      display: none !important;
      //background-color: $lightblue;
      //
      //td.actions {
      //  text-align: center !important;
      //  padding: 0;
      //  min-height: 48px;
      //}
      //
      //button.button[type='submit'] {
      //  background-color: transparent;
      //  //margin: 0 auto;
      //  width: 100%;
      //  color: $primary;
      //  //opacity: 1;
      //}
    }


    .coupon {
      //display: inline-flex;
      flex-direction: column;
      //padding: 12px 24px 0;
      //border-top: 12px solid $body-bg;
      //margin-bottom: 40px;
      //width: 50%;
      //max-width: 50%;
      //flex: 0 0 50%;
      @include media-breakpoint-down(md) {
        margin-bottom: 48px;

      }

      h4 {
        margin-bottom: 24px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 48px;
        }
      }

      input {
        max-width: calc(100% - 64px);
        margin: 0;
      }

      @include media-breakpoint-up(sm) {
        //padding-left: 56px;
        input {
          max-width: 286px;
        }
      }

      label {
        display: none;
      }

      .coupon-form {
        display: flex;
        align-items: center;

        button {
          text-indent: -9999px;
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: transparent;
          border: 2px solid $primary;
          position: relative;
          margin-left: 1rem;

          &:before {
            content: '';
            background: icon(icon-arrow-right, $primary) no-repeat;
            height: 24px;
            width: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }


  }

  .wc-proceed-to-checkout {
    background-color: $body-bg;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding: 32px 0 0 0;

    .proceed-shopping {
      margin: 24px 0 0 0;
      font-weight: $font-weight-bold;
      font-size: 20px;
    }

    a.checkout-button {
      margin-bottom: 0;
      @extend .btn-primary;
      height: 56px;
      border-radius: 28px;
      line-height: 56px;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: $font-weight-bolder;
      @include media-breakpoint-down(sm) {
        padding: 0 32px;
        height: 48px;
        font-size: 18px;
        line-height: 48px;
      }
      &.disabled, &.disabled:hover {
        background-color: #005a8e;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: flex-end;
      .proceed-shopping {
        margin: 0 40px 0 0;
      }
    }
  }
}


.woocommerce {
  .cart-item {
    .product-thumbnail {
      @media (min-width: 768px) and (max-width: 768px) {
        //display: none !important;
        display: table-cell !important;

      }
      //@include media-breakpoint-up(lg) {
      //  width: 192px !important;
      //}
    }
  }

  .cart-footer {
    background-color: $white;

    .row {
      padding: 24px;
      @include media-breakpoint-up(lg) {
        padding: 30px 56px;
      }
    }
    tr {
      border-top: 0 !important;
    }
  }

  .cart-collaterals table {
    &.shop_table {
      th {
        font-weight: $font-weight-base;
      }
    }
  }

  .cart-collaterals {
    .shop_table {
      //padding: 0 24px;
      color: $primary;
      font-size: 20px;
      margin: 0;

      span {
        font-weight: $font-weight-bolder;
      }
    }

    h2 {
      display: none;
    }

    tr {
      &:nth-child(2n) td {
        background-color: transparent;
      }

      th, td {
        padding: 8px 0;
        line-height: 24px;
        letter-spacing: .3px;
        background-color: transparent !important;

      }

      &.cart-discount {
        th, td {
          border: 0;
          background-color: transparent;
          position: relative;
        }
      }

      &.fee {
        th, td {
          //padding: 8px 0 20px;
          border: 0;

          @include media-breakpoint-up(lg) {
            padding: 8px 0;
          }
        }
      }
      &.tax-rate {
        th, td {
          border: 0;
          .amount, .amount span {
            font-weight: $font-weight-base;
          }
        }
        //&:only-of-type {
        //  th, td {
        //    padding: 8px 0;
        //  }
        //}
        //+ .tax-rate {
        //  th, td {
        //    padding: 8px 0 16px;
        //  }
        //}
      }
      &:nth-last-child(2) {
        th, td {
          padding: 8px 0 16px;
        }
      }
      th {
        background-color: transparent;
        border: none;
        font-weight: $font-weight-base;
      }

      td {
        border: none;
        //padding: 0 0 12px;
        //display: flex;
        //justify-content: space-between;
        text-align: right;
        @include media-breakpoint-down(sm) {
          //justify-content: flex-end;
          display: table-cell;
          text-align: right;
        }

        @include media-breakpoint-up(md) {
          justify-content: flex-end;
        }
        @media (min-width: 768px) and (max-width: 768px) {
          //@include media-breakpoint-only(md) {
          justify-content: space-between;
        }

        &:before {
          font-weight: $font-weight-base;
        }
      }
    }

    .cart_totals {
      width: 100%;
      float: none;

    }

    tr.order-total {
      th, span, td, bdi {
        font-size: 24px;
        line-height: 28px;
        font-weight: $font-weight-bolder;
        letter-spacing: 0;
      }
      th, td {
        padding-top: 22px;
        padding-bottom: 0 !important;
        border-top: 2px solid $info;
      }
    }
    .cart_totals tr {
      th {
        display: table-cell;
        white-space: nowrap;
        vertical-align: middle;
      }
      td:before, td:before {
        font-weight: $font-weight-base !important;
        display: none !important;
      }
      //span, td, bdi {
      //  font-weight: $font-weight-bolder;
      //}
    }
  }
}



.container-cart-empty {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
  .return-to-shop {
    margin-top: 80px;
  }
}

.woocommerce .woocommerce-remove-coupon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: icon(icon-delete, $primary) center no-repeat;
  background-size: 24px 24px;
  display: inline-flex;
  box-shadow: none;
  position: absolute;
  margin-left: 8px;
  //top: 8px;
  line-height: 32px;
  &:hover {
    background: $body-bg icon(icon-delete, $primary) center no-repeat;
    //background-size: 24px 24px;
    transition: $transition-base;
    box-shadow: none;
  }

  span {
    display: none;
  }
}

















