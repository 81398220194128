.theme-tetesept-ch {
  .mobileNav {
    .cartmenu {
      display: flex;
      margin-left: auto;
      margin-bottom: 0;
      z-index: 1000;

      .wpmenucartli {
        padding-left: 0;
        margin-right: 0;
        padding-right: 0;
        margin-bottom: 5px;
        margin-top: 0px;
      }
    }

    .navbar-toggler {
      margin-left: 12px;
    }

    .wpmenucartli .cartcontents {
      height: 24px;
      width: 24px;
      font-size: 12px;
      line-height: 22px;
      font-weight: $font-weight-bolder;
      left: 23px;
      top: 0;
    }

    #menu-primary-menu-1 {
      .wpmenucartli {
        display: none;
      }
    }

    .lang-sw {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        padding-left: 24px;
        //font-size: 20px;
        font-weight: $font-weight-bold;
        display: block;
      }
    }

    .language_switcher_twt {
      align-self: start;
      padding-left: 24px;
      padding-right: 24px;
      li {
        flex-grow: 0;
        font-size: 18px !important;

        + li {
          margin-left: 16px;

          &:before {
            top: 15px;
          }

          a {
            padding-left: 18px !important;

          }
        }

        a {
          border-bottom: 0 !important;
          font-size: 18px;
        }
      }
    }

    .navbar-nav {
      .wpmenucartli {
        display: none;
      }
    }
  }
}

.theme-tetesept-ch {
  &:not(.theme-formula) .mobileNav .navbar-brand img {
    height: 28px !important;
  }

  header {
    ul.navbar-nav {
      //.wpmenucartli {
      //  display: none;
      //}
      li a {
        display: inline-flex;
        align-items: center;
        height: auto;

        &[aria-expanded]:after {
          bottom: 0;
        }
      }
    }

    [class*='language'] {
      //       background-color: red;
    }
    .lang-sw span {
      display: none;
    }
    .language_switcher_twt {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      padding: 0 30px;

      li {
        padding-bottom: 6px;
        display: flex;
        align-items: center;
        list-style: none;
        //padding-bottom: 0;
        padding-left: 0;
        position: relative;
        font-size: 18px;

        &.active {
          color: $primary;
          font-weight: $font-weight-bolder;
          font-size: 20px;

        }

        + li {
          padding-left: 12px;
          margin-left: 10px;

          &:before {
            content: '';
            position: absolute;
            background: $primary;
            height: 24px;
            width: 2px;
            left: 0;
            top: 4px;
          }
        }

        a {
          font-weight: $font-weight-base;
        }

        &:before {
          content: none;
        }

        &:not(.active) {
          font-weight: $font-weight-bolder;
        }
      }
    }

    .search {
      margin-right: 0 !important;
    }
  }

  header li.search a {
    padding-left: 0;
    //background-color: red;
  }

  .wpml-ls-flag {
    display: none;
  }

  .navbar-toggler {
    border: 0;
  }

  .wpmenucart-icon-shopping-cart-0:before {
    background: icon(icon-cart, $primary) center no-repeat;
    height: 32px;
    width: 32px;
    //     background-size: 24px 24px;
    content: '';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    //     margin-top: auto;
    //     margin-bottom: auto;
  }

  #main-nav {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row-reverse;
      //margin-right: 12px;
    }
  }

  .wpml-ls-current-language {

  }

  .cartmenu {
    display: none;
  }

  .wpmenucartli {
    order: 3;
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 18px;
    padding-right: 18px;
    margin-top: 4px;
    //a {
    //  display: inline-flex;
    //  align-items: center;
    //}
    @media (min-width: 1468px) {
      margin-right: calc((100vw - 1452px) / 2);
    }
    @media (min-width: 1568px) {
      margin-right: 64px;

    }

    i {
      height: 32px;
      display: inline-block;
      line-height: 0;
    }

    .cartcontents {
      position: absolute;
      top: -7px;
      left: 54px;
      height: 32px;
      width: 32px;
      //right: -10px;
      background-color: $lighterblue;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: .4px;
      min-height: 24px;
      min-width: 24px;
      border-radius: 24px;
      //padding: 4px 10px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

}