.woocommerce-info {
  background-color: $lightblue;
  border-top: 0px;
  margin-bottom: 0;

  .showcoupon {
    margin-left: 8px;
    //text-decoration: underline;
    //box-shadow: 0px 0 2px 0 blue;
    box-shadow: 0 1px 0 0 $primary;

    &:hover {
      box-shadow: 0 1px 0 0 $link-hover-color;
    }
  }

  &.woocommerce-coupon {
    &:before {
      content: '';
      background: icon(icon-cart-voucher, $primary) center/28px 28px no-repeat;
      height: 28px;
      width: 28px;
      top: 1.25rem;
    }
  }


  + .coupon-wrapper {
    margin-top: 2px;
    background-color: $white;

    .woocommerce-message {
      background-color: $white;
    }

    form.checkout_coupon {
      background-color: $white;
      border: 0px;
      margin: 0;
      padding: 40px 0;
      @include media-breakpoint-up(md) {
        padding: 72px 0;
      }

      .form-row {
        padding: 0 12px;
        margin: 0 0;
      }

      label {
        position: absolute;
        left: 16px;
      }

      .focus label, .has-input label {
        top: -36px;
      }

      .input-text {
        margin-right: 40px;
      }

      .input-group {
        @include media-breakpoint-down(sm) {
          [name="coupon_code"] {
            margin-right: 16px;
          }
          + .input-group {
            width: auto;

            &:before {
              content: '';
              background: icon(icon-arrow-right, $primary) no-repeat;
              height: 24px;
              width: 24px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-size: 24px 24px;
            }
          }
        }
      }

      input.button {
        @include media-breakpoint-down(sm) {
          background-color: transparent;
          //font-size: 0;
          width: 48px;
          height: 48px;
          padding: 0;
          position: relative;
          text-indent: -9999px;
          top: 4px;
        }

        @extend .btn;
        @extend .btn-primary;
        //@extend .btn-lg;
        width: auto;
        border-radius: 28px;
      }

      [class^='col-'] {
        display: flex;
      }
    }
  }
}


.woocommerce-message {
  min-height: 68px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $lighterblue;

  &:before {
    content: '';
    background: icon(icon-cart-checked, $primary) center/28px 28px no-repeat;
    height: 28px;
    width: 28px;
    top: 1.25rem;
  }

  &.success {
    background-color: $lightblue;

    &:before {
      content: '';
      background: icon(icon-cart-checked, $primary) center/28px 28px no-repeat;
      height: 28px;
      width: 28px;
      top: 1.25rem;
    }
  }

  &.woocommerce-delete {
    &:before {
      content: '';
      background: icon(icon-cart-delete, $primary) center/28px 28px no-repeat;
      height: 28px;
      width: 28px;
      top: 1.25rem;
    }
  }

  border: 0;
  margin: 0;
  font-weight: $font-weight-bold;
  align-items: center;
  display: flex;

  .button.wc-forward {
    background-color: transparent;
    color: $primary;
    letter-spacing: .3px;
    order: 1;
    margin-left: auto;
    //display: flex;
    //align-items: center;
    box-shadow: 0 1px 0 0 $primary;
    padding: 0;
    border-radius: 0;
    height: auto;
    min-height: auto;
    transition: $transition-base;
    padding-top: 0px;
    padding-bottom: 0px;

    &:hover {
      box-shadow: 0 2px 0 0 $link-hover-color;
      background-color: transparent;
      color: $link-hover-color;
      letter-spacing: .3px;
      transition: $transition-base;
    }

    //font-weight: $font-weight-bold;
  }
}

.woocommerce-notices-wrapper {
  *, + .woocommerce-info, + .woocommerce-error {
    border: 0;
    color: $primary;
    letter-spacing: .3px;
    font-weight: $font-weight-bold;
    font-size: 1rem * 1.25;
    line-height: 1rem * 1.5;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 0;
    //padding-top: 6px;
    //padding-bottom: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      //flex-direction: column;
      //align-items: flex-start;
      a {
        //margin: 8px 0 4px !important;
        //margin-top: 8px !important;
      }
    }
  }
  .woocommerce-error {
    left: 0;
    width: 100%;
  }

  a {
    padding: 0 0 0 16px;
  }

  span {
    //padding: 4px 0;
    padding: 0 0 0 4px;
  }

  [class^="woocommerce-"] {
    + [class^="woocommerce-"] {
      margin-top: 2px;
    }
  }

  .cart-empty.woocommerce-info, + .cart-empty.woocommerce-info, + .woocommerce-error, .woocommerce-error {
    background-color: #fcdbd9;
    min-height: 68px;

    &:before {
      content: '';
      background: icon(icon-cart-warning, $primary) center/28px 28px no-repeat;
      height: 28px;
      width: 28px;
      //top: 1.25rem;
      top: 18px;
    }

    li {
      &:first-child, &:only-child {
        padding-top: 0;
      }

      &:before {
        content: none;
      }
    }
  }
}

$inner-padding: 44px;

.woocommerce-message.success, .cart-empty.woocommerce-info, .woocommerce-error, .woocommerce-message, .woocommerce-coupon {
  @include media-breakpoint-up(xl) {
    padding-left: calc(12px + #{$inner-padding});
  }
  @media (min-width: 1464px) {
    padding-left: calc(((100vw - 1452px) / 2) + #{$inner-padding});
  }
  @media (min-width: 1568px) {
    padding-left: calc(64px + #{$inner-padding});
  }
}

.woocommerce-error {
  border-top: 0;
  background-color: #fcdbd9;
  min-height: 68px;
  //width: calc(100% + 48px);
  //left: -12px;
  color: $primary;
  letter-spacing: .3px;
  font-weight: $font-weight-bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:before {
    content: '';
    background: icon(icon-cart-warning, $primary) center/28px 28px no-repeat;;
    height: 28px;
    width: 28px;
    top: 18px;
  }
  li {
    &:before {
      content: none !important;
    }
  }
}

.woocommerce-message.success:before, .woocommerce-notices-wrapper .cart-empty.woocommerce-info:before, .woocommerce-notices-wrapper + .cart-empty.woocommerce-info:before, .woocommerce-error:before, .woocommerce-message:before, .woocommerce-coupon:before {
  //@include media-breakpoint-up(xl) {
  //  left: calc((100vw - 1452px) / 2);;
  //}
  @include media-breakpoint-up(xl) {
    left: 12px;
  }
  @media (min-width: 1464px) {
    left: calc((100vw - 1452px) / 2);
  }
  @media (min-width: 1568px) {
    left: 64px;
  }
}

