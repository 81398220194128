.theme-tetesept-ch {
  .gp-section-card-product {
    .card-product-slider {
      .card {
        .card-header {
          padding: 56px 40px 0;
          picture {
            max-height: 200px;
            max-width: 200px;
            width: 200px;
            height: 200px;
          }
        }
        .card-body {
          align-items: center;
          h6, .sub-category {
            text-align: center;
          }
        }
      }
    }
    .card {
      .card-header {
        picture {
          max-height: 200px;
          max-width: 200px;
          margin: 0 auto;
          //img {
          //  margin: 0 auto;
          //}
        }
      }

      .card-body {
        padding: 24px 20px 20px;
        align-items: stretch;
        a {
          text-align: center;
        }
      }

      .added_to_cart {
        display: none;
      }

      @include media-breakpoint-down(xs) {
        flex-direction: row;
        .card-header {
          padding: 20px 0px 0 20px;
          //padding: 56px 40px 0;
          picture {
            max-height: 96px;
            max-width: 96px;
            width: 96px;
            height: 96px;
          }
        }
        .card-body {
          align-items: flex-start;
          padding: 20px;
          h6 {
            margin-top: 4px;
          }
          * {
            text-align: left;
          }
          .sub-category {
            text-align: left;
          }
          //.woocommerce-Price-amount {.sub-category
          //  bdi {
          //  }
          //}
        }
        .product-price-add-to-cart {
          padding-left: 0;
          padding-top: 12px;
        }
        .add_to_cart_button {
          height: 48px;
          width: 48px;
          background-size: 24px 24px;
        }
      }
    }

    h6 {
      font-size: 1rem * 1.25;
      line-height: 1rem * 1.5;
    }

    .product-price-add-to-cart {
      background-color: $white;
      display: flex;
      padding: 16px 0 0;
      width: 100%;
      margin-top: auto;

      .price {
        display: flex;
        //align-items: center;
        flex-direction: column;
        color: $gray-700;
        letter-spacing: .3px;
        font-weight: $font-weight-bolder;
        justify-content: center;
        font-size: 20px;
        del, del * {
          line-height: 24px;
          font-size: 1rem;
          opacity: 1;
          font-weight: $font-weight-base;
        }
        del {
          margin-bottom: 2px;
        }

        ins, ins * {
          text-decoration: none;
          font-size: 1rem * 1.25;
          line-height: 24px;
          //span {
          //  font-size: 1rem * 1.25;
          //}
        }
      }
      a.product_type_simple:not(.add_to_cart_button) {
        @extend .btn;
        @extend .btn-primary;
        //height: 56px;
        border-radius: 24px;
      }
    }

    .add_to_cart_button {
      text-indent: -9999px;
      font-size: 0;
      height: 56px;
      width: 56px;
      //background-color: $primary;
      border-radius: 50%;
      margin-left: auto;
      background: $primary icon(icon-cart, $white) center/32px 32px no-repeat;

      &:hover {
        background: $link-hover-color icon(icon-cart, $white) center/32px 32px no-repeat;
      }
    }

  }
}
