// input up and down
// ----------------------------------------------------------------------------------
.quantity {
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity input {
  font-weight: $font-weight-bolder;
  font-size: 20px;

  &:focus, &:hover {
    outline: 0;
  }
}

.quantity-button {
  cursor: pointer;
  user-select: none;
  position: absolute;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: $lighterblue;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: $primary;
    height: 2px;
    width: 16px;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
  }

  &.quantity-up {
    right: 2px;

    &:before {
      content: '';
      position: absolute;
      background-color: $primary;
      height: 2px;
      width: 16px;
      top: 50%;
      left: 14px;
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &.quantity-down {
    left: 2px;

    &:after {
      content: '';
      position: absolute;
      background-color: $primary;
      height: 2px;
      width: 16px;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
    }
  }
}

@include media-breakpoint-down(sm) {
  .quantity .qty {
    width: 104px;
    height: 48px;
  }
  .single_add_to_cart_button.button {
    min-height: auto;
    max-height: 48px;
    font-size: $font-size-base;
    line-height: 24px;
    padding: 0 32px;
    height: 48px;
  }
}

.woocommerce form .form-row {
  label, label span {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    top: 16px;
  }
  input {
    padding: 16px;
    &.focus {
      outline: 0;
      border-bottom: 2px solid $secondary;
    }
  }
  &.focus, &.has-input {
    label, label span {
      top: -38px;
      line-height: 24px;
      font-size: 16px;
    }
  }
  &.woocommerce-validated {
    input.form-control {
      border-color: $lightblue;
    }
  }
  &.woocommerce-invalid {
    input.form-control {
      border-color: $lightblue;
    }
    &:after {
      content: '!';
      position: absolute;
      right: calc(12px + 16px);
      @include media-breakpoint-down(md) {
        right: calc(24px + 16px);
      }
      height: 20px;
      width: 20px;
      background-color: #dc3545;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      text-align: center;
      line-height: 20px;
      font-weight: 900;
      border-radius: 50%;
      font-size: 14px;
      z-index: 10;
    }
  }

}
