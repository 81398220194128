.theme-tetesept-ch.woocommerce {
  .gp-stage-product {
    .quantity {
      margin-right: 16px !important;

      .qty {
        background-color: $white;
        border-radius: 28px;
        border: 2px solid $lightblue;
        height: 56px;
        width: 164px;
        margin: 0;
        @include media-breakpoint-down(sm) {
          height: 48px;
        }
      }
      .quantity-button {
        height: 52px;
        width: 52px;
        @include media-breakpoint-down(sm) {
          height: 44px;
          width: 44px;
        }
      }
      .quantity-down, .quantity-up {
        &:before, &:after {
          left: 18px;
          @include media-breakpoint-down(sm) {
            left: 15px;
          }
        }
      }
    }

    .single_add_to_cart_button {
      @extend .btn-primary;
      border-radius: 28px;
      height: 56px;
      padding: 16px 24px;
      font-weight: $font-weight-bolder;
      font-size: 1rem * 1.25;
      @include media-breakpoint-down(sm) {
        text-indent: -9999px;
          text-indent: -9999px;
          font-size: 0;
          height: 48px;
          width: 48px;
          border-radius: 50%;
          //margin-left: auto;
          background: $primary icon(icon-cart, $white) center/24px 24px no-repeat;
      }
    }

    form.cart {
      //display: inline-flex;
      //align-self: flex-end;
      //margin: 0 0 0 auto;
      //margin: auto 0 8px auto;
      margin: 0 0 0 auto;
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
      @include media-breakpoint-only(lg) {
        margin: 0;
      }
    }


    .price {
      color: $body-color;
      display: inline-flex;
      margin: 0;
      flex-direction: column;
      margin-right: 16px;
      del {
        opacity: 1;

        span {
          font-size: 24px;
          line-height: 28px;
        }
      }

      ins {
        text-decoration: none;
        font-weight: $font-weight-bolder;
        line-height: 1rem * 2.75;

        span {
          font-size: 1rem * 2;
          text-decoration: none;
          line-height: 1rem * 2.75;
          @include media-breakpoint-up(lg) {
            font-size: 1rem * 2.5;
            line-height: 1rem * 4;
          }
        }
      }
    }
    .out-of-stock {
      margin-bottom: 0;
      align-self: flex-end;
      font-weight: bold;
    }

    .product-price-add-to-cart {
      display: flex;
      margin: 32px 0 32px;
      .price > span * {
        font-size: 2rem;
        line-height: 2.75rem;
        font-weight: $font-weight-bolder;
      }
      @include media-breakpoint-up(lg) {
        margin: 56px 0;
        .price > span * {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      @include media-breakpoint-only(lg) {
        flex-direction: column;
      }
    }


    @include media-breakpoint-down(sm) {
      .attribute_logos picture {
        margin-bottom: 32px;
      }
      .col-lg-6.col-xl-7 {
        display: flex;
        flex-direction: column;

        * {
          order: 10;
        }

        .product_title.entry-title {
          order: 1;
          margin-bottom: 20px;
        }

        .price {
          order: 2;
          margin-bottom: 20px;
        }

        .cart {
          order: 3;
          //margin-bottom: 32px;
        }

        .product-price-add-to-cart {
          order: 3;
        }
      }
    }
  }
}




