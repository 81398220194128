.theme-tetesept-ch {
  .site-footer {
    [class^='icon-'] {
      text-indent: -9999px;
      width: 72px;
      height: 47px;
      background-size: 72px 47px;
      display: inline-block;
      margin-right: 7px;
      margin-bottom: 12px;
      @include media-breakpoint-down(sm) {
        margin-right: 3px;
        margin-bottom: 8px;

      }

      &:before {
        content: none;
      }
    }

    .icon-master-card {
      background-image: url("/app/themes/tetesept-ch/theme/media/icons/icon-mastercard.svg");
    }

    .icon-paypal {
      background-image: url("/app/themes/tetesept-ch/theme/media/icons/icon-paypal.svg");
    }

    .icon-post {
      background-image: url("/app/themes/tetesept-ch/theme/media/icons/icon-post.svg");
    }

    .icon-rechnung {
      background-image: url("/app/themes/tetesept-ch/theme/media/icons/icon-rechnung.svg");
    }

    .icon-visa {
      background-image: url("/app/themes/tetesept-ch/theme/media/icons/icon-visa.svg");
    }

    .footer-nav {
      @include media-breakpoint-down(md) {
        padding-bottom: 64px;
      }
    }

    .col-lg-9 {
      &:after {
        @include media-breakpoint-up(sm) {
          width: calc(100% - 24px) !important;
          left: 12px !important;
        }
      }

    }

    .nested {

      @include media-breakpoint-down(xs) {
        margin: 0 -24px;
      }
      section {
        width: 100%;
      }

      button {
        width: 100%;
        padding-top: 14px;
        @include media-breakpoint-between(sm, md) {
          &:before {
            width: 100% !important;
            left: 0 !important;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:first-child {
          margin-bottom: 40px;
        }
      }

      .col {
        @include media-breakpoint-down(md) {
          width: 100%;
          flex: 0 0 100%;
          margin-top: 14px;
          overflow-x: hidden;
          //padding: 0 12px;
        }
        @include media-breakpoint-up(xl) {
          //margin-left: calc((100% / 12) );
        }
        @media (min-width: 1464px) {
          margin-left: calc((1464px / 12));
        }
      }
      .collapse, .collapsing {
        a {
          padding: 10px 0;
          margin: 0;
        }
      }
      a {
        box-shadow: none !important;
        font-weight: $font-weight-bold;
      }
    }
  }
}
