.checkout-heading {
  text-align: center;
  margin: 56px auto 32px;
  @include media-breakpoint-up(lg) {
    margin: 80px auto 48px;
    text-align: center;
  }
}

body.woocommerce-checkout {
  .entry-content > .woocommerce {
    > .container {
      //margin-top: 56px;
      //@include media-breakpoint-up(lg) {
      //  margin-top: 120px;
      //}
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
        .row {
          margin: 0;
        }
      }

      .col-12 {
        @include media-breakpoint-down(md) {
          padding: 0;
          .container-fluid {
            padding-left: 0;
            padding-right: 0;

            .form-row {
              padding-left: 24px;
              padding-right: 24px;
            }
          }
        }
      }
    }

    .woocommerce-billing-fields {
      .container-fluid {
        @include media-breakpoint-down(md) {
          //padding-left: 0;
          //padding-right: 0;
          //.form-row {
          //  padding-left: 24px;
          //  padding-right: 24px;
          //}
          label {
            left: 40px;
          }
        }
      }
    }
  }
  p.woocommerce-invalid-required-field  {
    &.woocommerce-invalid:after {
      top: 18px;
      transform: none;
      padding-right: 1px;
    }
    span.error {
      display: block !important;
      position: relative;
      color: $red !important;
      top: 10px !important;
      //font-weight: $font-weight-bold !important;
      letter-spacing: 0.3px;
      font-size: 16px;
      line-height: 24px;
      left: 16px;
    }
  }
  .woocommerce-NoticeGroup-checkout .woocommerce-error {
    display: none;
  }
}

// Lieferadresse
.checkout.woocommerce-checkout {
  .form-row.woocommerce-validated select {
    border-bottom: 2px solid #c8dff2;
  }

  .extra-fields {
    p {
      &:before {
        content: '';
        position: absolute;
        right: 12px;
        @include media-breakpoint-down(md) {
          right: 24px;
        }
        top: 0px;
        background: icon(icon-quantity-down, $primary) center/12px 16px no-repeat;
        height: 56px;
        width: 54px;
        pointer-events: none;
      }
    }

    select {
      appearance: none;
    }
  }

  @include media-breakpoint-up(lg) {
  }

  .form-row {
    @include make-col-ready();
    width: 100%;
    float: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin: 27px 0;
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(4);
      margin: 32px 0;
      &:nth-child(odd) {
        margin-left: calc((100% / 12) * 2);
      }
      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    *:not(h4), *:not(a) {
      color: $dark !important;
    }

    &.validate-postcode {
      max-width: 200px;
      @include media-breakpoint-up(lg) {
        max-width: 160px;
      }

      + .form-row {
        //max-width: calc(100% - (((100% / 12) * 2) + 160px));
        //flex: 0 0 calc(100% - (((100% / 12) * 2) + 160px));
        @include media-breakpoint-up(lg) {
          //max-width: calc(33.33333% - 160px);
          //margin-left: 0;
          max-width: calc(50% - 160px);
          margin-left: 0;
        }
        @include media-breakpoint-up(xl) {
          max-width: calc(33.33333% - 160px);
        }
        //+ .form-row {
        //  //margin-left: calc((100% / 12) * 2);
        //  //margin-right: calc((100% / 12) * 6);
        //  margin-left: 0;
        //}

      }
    }

    &.single-country, &.validate-email {
      margin-left: 0;
    }

    &.validate-phone {
      //margin-left: 0;
      @include media-breakpoint-up(xl) {
        margin-left: calc((100% / 12) * 2);
      }
    }
  }

  .headline {
    .row {
      background-color: transparent;

      h3, h2 {
        text-align: center;
      }
    }

    h4 {
      margin-top: 8px;
    }

    ~ .container-fluid .row {
      //position: relative;
      background-color: $white;
    }

    + .container-fluid {
      h3 {
        margin-top: 40px;
        @include media-breakpoint-up(lg) {
          margin-top: 80px;
        }
        @include media-breakpoint-between(sm, md) {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
  }

  .woocommerce-shipping-fields {
    .container-fluid .row {
      background-color: $white;

      .form-row {
        @include media-breakpoint-up(lg) {
          @include make-col(12);
          margin-bottom: 80px;
        }
        @include media-breakpoint-up(xl) {
          @include make-col(8);
        }
      }

      textarea {
        min-height: 208px;
      }
    }

    h4 {
      @include media-breakpoint-between(sm, md) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    label {
      @include media-breakpoint-down(md) {
        left: 40px;
      }
    }

    textarea {
      padding: 16px;
    }
  }

  .extra-fields {
    width: 100%;
    @include make-col(12);

    .form-row {
      margin-left: 0;
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    @include media-breakpoint-up(xl) {
      @include make-col(8);
      margin-left: calc((100% / 12) * 2);
    }

  }

  label {
    position: absolute;
    left: calc(12px + 16px);
    left: 12px;
    transition: all 0.2s ease-in-out;
    color: $dark;
    z-index: 10;
    top: 10px;
    @include media-breakpoint-up(lg) {
      //top: 54px;
      left: 28px;
    }
    font-size: 18px;

    .wpforms-required-label {
      color: $dark;
      line-height: 1.3;
    }

    &.wpforms-field-label.checklist-heading {
      position: relative;
      font-weight: $font-weight-bold;
      position: relative;
      top: 0;
      left: 0;
      padding: 0;
      margin-bottom: 24px;
      font-size: 20px;
      margin-top: 32px;
    }
  }

  .woocommerce-input-wrapper {
    width: 100%;
  }

  input, select, textarea {
    border-radius: 0;
    background-color: #e7f2fa;
    border: 0;
    min-height: 56px;
    border-bottom: 2px solid #c8dff2;
    letter-spacing: 0.3px;
    font-weight: 700;
    margin: 26px 0;
    font-size: 18px;
    padding: 12px;
    max-width: none;
    color: #005a8e;
    letter-spacing: 0.3px;
    width: 100%;
  }

  select {

  }
}

#order_review_heading {
  text-align: center;
  margin: 40px 0 32px;
  @include media-breakpoint-up(lg) {
    margin: 80px 0 48px;
  }
}

[class^='select2-'] {
  &:focus, &:active {
    outline: none;
  }
}

.select2-container--default {
  //  &:focus {
  //    outline: none;
  //  }

  .select2-selection--single {
    border-radius: 0;
    background-color: $lighterblue;
    border: 0;
    min-height: 56px;
    border-bottom: 2px solid $lightblue;
    letter-spacing: 0.3px;
    font-weight: 700;
    font-size: 18px;
    padding: 12px;
    max-width: none;
    color: $primary;
    letter-spacing: 0.3px;
    width: 100%;

    .select2-selection__arrow {
      width: 56px;
      right: 0;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;

      b {
        left: auto;
        position: relative;
        margin: 0;
        bottom: -1px;
        background: icon(icon-quantity-down, $primary) center/12px 16px no-repeat;
        height: 28px;
        width: 28px;
        border: 0px;
        top: auto;
      }
    }

    &[aria-expanded="true"] {
      .select2-selection__arrow {
        b {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.select2-dropdown.select2-dropdown--below {
  background-color: $white;
  border-color: $lightblue;
  border-radius: 0;
  margin-top: -2px;
  position: relative;

  &:after {
    content: '';
    width: calc(100% + 2px);
    height: 2px;
    position: absolute;
    background-color: $secondary;
    top: 0;
    left: -1px;
  }

  .select2-search {
    display: none;
  }

  .select2-results__option {
    &:first-of-type {
      display: none;
    }
  }
}

.select2-results li.select2-results__option {
  list-style: none;
  background-color: transparent;
  padding: 16px;
  color: $dark;
  font-weight: $font-weight-bold;
  line-height: 24px;
  font-size: 18px;
  letter-spacing: .3px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $lightblue;
  }

  &:before {
    content: none;
  }

  &.select2-results__option--highlighted {
    background-color: $lighterblue;
    color: $primary;
  }

  &[data-selected=true] {
    background-color: transparent;
  }
}


// Produktliste
.woocommerce-checkout-review-order {
  .shop_table, .woocommerce-checkout-payment, #payment.woocommerce-checkout-payment {
    background-color: $white;
  }

  table.shop_table {
    border: 0;
    border-radius: 0;
    padding: 0;
    border-collapse: collapse;
    //margin-bottom: 40px;
    //padding-bottom: 12px;
    //padding-top: 12px;
    //@include media-breakpoint-up(lg) {
    //  margin-bottom: 80px;
    //  padding-bottom: 28px;
    //  padding-top: 28px;
    //}
    //.product-thumbnail {
    //  a {
    //    max-width: 96px;
    //    margin: 20px 24px 20px 32px;
    //    display: flex;
    //    box-shadow: none;
    //    justify-content: center;
    //    align-items: center;
    //    box-shadow: none;
    //    @include media-breakpoint-up(lg) {
    //      margin: 20px 40px 20px 56px;
    //    }
    //  }
    //}


    td, * {
      line-height: 28px;
      //line-height: 24px;
    }

    tr {
      //min-height: 138px;
      background-color: whsite;
      display: table-row;

      th {
        background-color: transparent;
        padding: 12px 0;
      }
    }

    @include media-breakpoint-down(sm) {
      tr.cart_item {
        min-height: 132px;
        min-height: auto;
        padding-bottom: 20px;
        grid-template-areas:
        'a b b b b'
        'a d d c c';

        td.product-quantity {
          grid-area: d;
          padding: 0;
          margin-left: 0;

          .product-quantity {
            padding: 0;
          }

          &:before {
            content: 'Anzahl: ';
            display: inline-block;
          }
        }

        td.product-total {
          grid-area: c;
        }

      }
    }

    .cart_item td {
      border-top: 0;
      padding: 10px 0;
      font-size: 1rem * 1.25;
      padding: 0;
      @include media-breakpoint-up(md) {
        border-top: 4px solid #f6f4f2;
      }
      @include media-breakpoint-down(sm) {
        &.product-quantity {

        }
      }

      &:not(.product-name) {
        text-align: right;
      }

      &.product-thumbnail {
        @include media-breakpoint-up(md) {
          width: 192px !important;
        }
      }
    }

    th.product-total, td.product-total {
      padding-right: 24px;
      @include media-breakpoint-up(lg) {
        padding-right: 56px;
      }
    }

    .product-total {
      text-align: right;
    }

    tbody {
      //border-collapse: collapse;
      //margin-top: 4px;
      td.product-quantity {
        text-align: left !important;
      }

      tr:last-of-type {
        td {
          padding-bottom: 18px;
        }
      }
    }

    .cart-discount, cart-subtotal {
      .woocommerce-remove-coupon {
        top: 10px;
      }
      th, td {
        border-top: 0 !important;
      }

      th span {
        font-weight: $font-weight-base;
      }

      span, a {
        vertical-align: middle;
      }
    }

    tfoot {
      position: relative;
      padding-right: 56px;
      //width: 50%;
      //display: flex;
      //flex-direction: column;
      //display: table-row-group;
      .test {
        //max-width: 0;
        //display: none;
        @include media-breakpoint-up(lg) {
          min-width: 50%;
        }
        //+ td {
        //  display: none;
        //}
      }

      th, td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
      }

      tr {
        //display: flex;
        min-width: 100%;

        &:first-child {
          border-top: 16px solid #f6f4f2;
        }

        td:last-of-type {
          padding-right: 56px;
        }
      }

      .cart-subtotal {
        td, th {
          padding-top: 40px !important;
        }

      }


      tr:first-of-type {
        th, td {
          padding-top: 20px;
        }
      }

      .order-total {
        th, span, strong, th + td, bdi {
          font-size: 24px;
          line-height: 28px;
        }

        span {
          font-weight: $font-weight-bolder;
        }

        th, td {
          padding-bottom: 40px;
        }

        th, th + td {
          //border-top: 2px solid $lighterblue;
          //line-height: 28px;
          padding-top: 22px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            //width: calc(100% - 138px);
            width: 100%;
            height: 2px;
            background-color: $lightblue;
            left: 0;
            top: 0;
          }
        }

        th + td {
          &:before {
            width: calc(100% - 56px);
          }
        }
      }
    }

    tfoot {
      *, th, td {
        color: $primary;
        border-top: 0;
      }

      th {
        font-weight: $font-weight-base;
        //font-size: $font-size-base;
      }

      td span {
        font-weight: $font-weight-bolder;
      }

      @include media-breakpoint-down(lg) {
        tr {
          display: grid;
          grid-template-areas:
        'a b b b'
        'a c c c'
        'd d f e';
          grid-template-columns: 0px 0px 300px 300px;
          grid-column-gap: 0;
        }
      }
    }
  }

  .cart-footer {
    > .row {
      @include media-breakpoint-down(sm) {
        padding: 12px;
        td {
        }
      }
    }
  }
}

//  Zahlungsart
#payment {
  @include media-breakpoint-only(sm) {
    padding-left: 12px;
    padding-right: 12px;
  }
  @include media-breakpoint-only(md) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include media-breakpoint-only(lg) {
    padding-left: 56px;
    padding-right: 56px;
  }

  .form-row {
    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  label {
    width: 100%;
  }
  .payment_icon {
    margin: 0 0 0 auto !important;
    height: 42px;
    + .payment_icon {
      margin-left: 8px !important;
    }
  }
  &.woocommerce-checkout-payment {
    border-radius: 0;
  }

  .wc_payment_method div.payment_box {
    //margin-top: 24px !important;
    border-radius: 0;
    //background-color: $lightblue;
    //color: $primary;
    //font-weight: $font-weight-bold;
    background-color: transparent;
    padding: 0 !important;

    &:before {
      content: none;
    }

    p:empty {
      display: none;
    }
    &.payment_method_bs_payone_creditcard {
      > p {
        display: none;
      }
    }

    > p, fieldset {
      margin-top: 24px !important;
      border-radius: 0;
      background-color: $lightblue;
      color: $primary;
      font-weight: $font-weight-bold;
      padding: 24px 36px;
      margin: 1em 0;
    }

    fieldset {
      //display: flex;
      @include media-breakpoint-down(sm) {
        .form-row {
          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            margin-bottom: 0;
          }
        }
      }
      .form-row:first-child {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          right: 12px;
          top: auto;
          bottom: 0;
          background: icon(icon-quantity-down, $primary) center/12px 16px no-repeat;
          height: 56px;
          width: 54px;
          pointer-events: none;
        }

        select {
          appearance: none !important;
          max-height: 56px;
          line-height: 24px;
          font-family: $font-family-base !important;
          font-size: 18px !important;
          letter-spacing: 0.3px;
          font-weight: $font-weight-bold;
        }
      }

      //.form-row:last-of-type {
      //  margin-bottom: 0 !important;
      //}

      [for="cvcInput"] {
        width: 100%;
      }

      .inputIframe {
        //display: block;
      }

      #cardexpiremonth {
        width: 50px;
        overflow: hidden;
      }

      label {
        position: relative;
        left: 0;
        top: auto;
        margin-bottom: 16px;
        width: 100%;
      }

      input, select, textarea {
        border-radius: 0;
        background-color: $white;
        border: 0 !important;
        min-height: 56px;
        border-bottom: 2px solid rgba($primary, .5) !important;
        margin: 26px 0;
        font-size: 18px;
        padding: 16px !important;
        max-width: none;
        color: #005a8e;
        width: 100%;
        //font-weight: $font-weight-base;
        line-height: 24px;
        font-family: $font-family-base !important;
        font-size: 18px !important;
        letter-spacing: 0.3px;
        font-weight: $font-weight-bold;

      }
    }

  }

  margin-top: 40px;
  padding-top: 40px;
  @include media-breakpoint-up(lg) {
    margin-top: 80px;
    padding-top: 80px;
  }

  h4 {
    margin-bottom: 24px;
  }

  ul.wc_payment_methods.payment_methods {
    padding: 0;
    border-top: 2px solid $lightblue;
    border-bottom: 2px solid $lightblue;
    margin: 0 0 24px 0;
    @include media-breakpoint-up(lg) {
      margin: 0 0 40px 0;
    }
  }

  li {
    //background: none !important;
    //border: 0 !important;
    //margin: 0 !important;
    //padding: 0 !important;
    list-style: none !important;
    padding: 20px 0;
    line-height: 24px !important;

    &:before {
      content: none;
      width: 0;
      height: 0;
    }

    + li {
      border-top: 2px solid $lightblue;
    }
  }

  div.payment_box.payment_method_cod {
    background-color: transparent;
    padding: 0;
    margin: 16px 0 0;

    &:before {
      content: none;
    }

    p {
      font-size: $font-size-base;
    }

  }

  &.woocommerce-checkout-payment {
    background-color: $white;
  }

  .form-row.place-order {
    width: 100%;
    max-width: none;
    flex: 0 0 auto;
    padding: 0 !important;
    margin: 0 0 40px 0;
    @include media-breakpoint-up(lg) {
      margin: 0 0 80px 0;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
    margin: 0;

    border: 1px solid #ccc;
    background-color: #fff;
    width: 14px;
    height: 14px;
    margin: 0 10px 0 3px;
    display: inline-block;
    vertical-align: baseline;
    min-height: auto;

    ~ * {
      padding-left: 36px !important;
      margin-top: 0 !important;
    }

    + label {
      position: relative !important;
      margin-bottom: 0;
      vertical-align: top;
      padding-left: 12px;
      padding-left: 36px;
      //margin-bottom: 24px;
      top: 0;
      left: 0;
      min-height: 24px;
      cursor: pointer;
      display: inline-block;
      line-height: 24px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      span {

        line-height: 24px;
        //flex: 0 0 80%;
        //max-width: 80%;
        //flex: 0 1 100%;
        //max-width: 100%;
        + picture {
          //flex: 0 0 20%;
          //max-width: 20%;
          //flex: 0 1 100%;
          //max-width: 100%;
          margin-left: auto;
        }
      }

      a {
        display: block;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        float: none !important;
        line-height: 24px !important;
        font-size: 1.25rem !important;
      }

      &:before {
        position: absolute;
        // top: 0.25rem;
        //top: 0;
        // left: -1.5rem;
        left: 0;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: 2px solid #005a8e;
        height: 24px;
        width: 24px;
        border-radius: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  input[type='radio'] {
    + label {
      &:before {
        border-radius: 50%;
        // left: -16px;
      }
    }

    &:checked ~ label:before {
      background-image: none;
    }

    &:checked ~ label:after {
      // border-color: #005a8e;
      position: absolute;
      content: "";
      background: $primary;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: 7px;
      top: 7px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .place-order {
    h4 {
      margin-bottom: 16px;
    }

    p, p a {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: .3px;
      margin-bottom: 0;
      &.wc-terms-and-conditions {
        padding: 0;
        padding-right: 48px;
        &.woocommerce-invalid-required-field {
          &:after {
            right: 16px;
            top: 6px;
          }
        }
      }
    }

    a {
      text-decoration: none;
      color: $primary !important;
      box-shadow: none;
    }

    input[type='checkbox'] + label a {
      display: inline;
      width: auto;
      flex: auto;
      font-size: 18px !important;
      margin-left: 6px;
    }

    .required {
      margin-top: 40px;
    }
  }

  .terms {
    width: 100%;
    max-width: none;
    flex: 0 0 auto;
    height: auto;
    margin: 24px 0 0;
    min-width: 100%;

    input {
      //position: absolute;
      //left: 0;
      //z-index: -1;
      //width: 1rem;
      //height: 1.25rem;
      //opacity: 0;
      //margin: 0;
      //
      //border: 1px solid #ccc;
      //background-color: #fff;
      //width: 14px;
      //height: 14px;
      //margin: 0 10px 0 3px;
      //display: inline-block;
      //vertical-align: baseline;
      &:checked ~ label::before {
        background: icon(icon-checkmark, $primary) center center no-repeat;
      }
    }

    label {
      position: relative !important;
      margin-bottom: 0;
      vertical-align: top;
      padding-left: 12px;
      padding-left: 36px;
      margin-bottom: 24px;
      top: 0;
      left: 0;
      min-height: 24px;
      cursor: pointer;
      display: inline-block;
      line-height: 24px;
      width: 100%;

      * {
        line-height: 24px;
        font-size: $font-size-base;
        text-decoration: none;
        box-shadow: none;
      }

      a {
        color: $primary !important;
      }

      &:before {
        //background: icon(icon-checkmark, $primary) center center no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: 2px solid #005a8e;
        height: 24px;
        width: 24px;
        border-radius: 3px;
      }
    }
  }

  .proceed-payment {
    display: flex;
    background-color: $body-bg;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      .btn {
        margin-left: 0 !important;
      }
    }

    .wc-backward {
      font-weight: $font-weight-bold;
    }

    input#place_order {
      float: none;
      @extend .btn;
      @extend .btn-primary;
      width: auto;
      border-radius: 28px;
      margin-left: 40px;
      font-size: 20px;
    }
  }
}









